<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Kamar -- " @input="doFill" v-model="filter.kamar" :options="mRanapKamar" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.bangsal" :options="mRanapBangsal" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template #cell(mrank_tarif)="v">
              Rp. {{v.value||"-"}}
            </template>
            <template
              #cell(mranbed_is_active) = "data"
            >
              <b-badge
                v-if="data.value=='Y'"
                variant="success"
              >Aktif</b-badge>
              <b-badge
                v-else
                variant="danger"
              >Nonaktif</b-badge>

              <b-badge
                v-if="data.item.mranbed_is_used=='Y'"
                variant="info"
                class="mt-1"
              >Kamar Terpakai</b-badge>
              <b-badge
                v-else
                variant="success"
                class="mt-1"
              >Kamar Kosong</b-badge>
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-pencil5"></i></router-link>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Delete"><i
                    class="icon-bin"></i></a>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('changeStatus')"
                  @click="doChangeStatus(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Change Status"><i
                    class="icon-cog6"></i></a>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('changeStatus')"
                  @click="doChangeStatusKamar(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Ubah Status Kamar"><i
                    class="icon-notification2"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'mranbed_id',
      statusKey:'mranbed_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mranbed_code',
          label: 'Kode',
        },
        {
          key: 'mranb_name',
          label: 'Nama Bangsal',
        },
        {
          key: 'mrankel_name',
          label: 'Nama Kelas',
        },
        {
          key: 'mrank_name',
          label: 'Nama Kamar',
        },
        {
          key: 'mranbed_name',
          label: 'Nama Bed',
        },
        {
          key: 'mrank_tarif',
          label: 'Tarif Kamar',
        },
        {
          key: 'mranbed_is_active',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      mRanapKamar: [],
      mRanapBangsal: [],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    doChangeStatusKamar(k,v, params={}){
      let id = v[this.idKey]
      let changeStatus = v['mranbed_is_used'] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Ubah Status Kamar ${changeStatus=='N' ? 'Kosong' : 'Terpakai'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ubah status ke ${changeStatus=='N'? 'Kosong' : 'Terpakai'}!`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay=true

          let slugs=""
          if(params.slugs) slugs = params.slugs

          Gen.apiRest(
            "/do/"+this.modulePage+slugs,
            {data:{type:'change-status-kamar',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v['mranbed_is_used'] = changeStatus        
              this.$swal({
                title: `Status Diubah Menjadi ${changeStatus=='Y'? 'Terpakai' : 'Kosong'}.`,
                icon: 'success',
              })
              this.apiGet()
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>